import styled from "styled-components/macro";
import { AutoColumn } from "../../components/Column";
import { Objectives } from "./components/Objectives";
import { GbtToken } from "./components/GbtToken";
import { Values } from "./components/Values";
import { Partnership } from "./components/Partnership";
import { MiningPower } from "./components/MiningPower";
import { Team } from "./components/Team";
import { Datatoken } from "./components/Datatoken";
import { GreenBlockData } from "./components/GreenBlockData";
import { Datacenter } from "pages/Datacenter";
import { Events } from "pages/Events";
import { NewToken } from "./components/NewToken";

const PageWrapper = styled(AutoColumn)`
  max-width: 1200px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`;


export function Home() {
  return (
    <PageWrapper>
      <Datatoken />
      <NewToken />
      <GreenBlockData />
      {/* <Community /> */}
      {/* <Container style={{marginTop: "5rem"}}>
        <YouTube videoId="KZuWQ7lQbnY" opts={opts} onReady={onPlayerReady} />
      </Container> */}
      {/* <RowCenter mt="2rem">
        <Iframe
          url="https://voltichange.net/api/widget/?chain=56&darktheme=true&tokenin=Native&tokenout=0xb8d5CC1462bf5a28366d58FfD4d40d5d26030F3F&slippage=0.5"
          width={isMobile ? "325px" : "500px"}
          height={isMobile ? "600px" : "500px"}
          frameBorder={0}
          display="block"
        />
      </RowCenter> */}
      <Objectives />
      <GbtToken />
      <MiningPower />
      {/* <NftPolygon /> */}
      <Values />
      <Team />
      <Partnership />
      <Datacenter />
      <Events />
    </PageWrapper>
  );
}
