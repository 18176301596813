import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
/* import { ButtonExternalLinkEmpty } from "components/Link";
 */
import { isMobile } from "utils/userAgent";

/* import Partner from "assets/images/partnership/gbtlogo.png";
import MinandoLogo from "assets/images/partnership/minandovoy.png"; */
import { BlueCard } from "components/Card";

const Container = styled.div`
  display: grid;
  place-items: center;
  margin-top: 10rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 5rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 5rem;
  `};
`;

const ResponsiveBox = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-content: center;
    align-items: center;
    gap: 1rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: center;
    align-items: center;
    gap: 1rem;
  `};
`;


export function NewToken() {
  return (
    <Container id={"partnership"}>
      <BlueCard width={isMobile ? '100%' : "980px"} padding={"40px"}>
        <ResponsiveBox>
          <ThemedText.SubTitle
            color="primary1"
          >
            <Trans>New GBC Contract</Trans>
          </ThemedText.SubTitle>
          <ThemedText.ExtraSmallText>0xb8d5CC1462bf5a28366d58FfD4d40d5d26030F3F</ThemedText.ExtraSmallText>
        </ResponsiveBox>

      </BlueCard>

    </Container>
  );
}
