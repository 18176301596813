import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { AutoColumn } from "components/Column";
import { stack as StackMenu } from "react-burger-menu";
import { useDarkModeManager } from "state/user/hooks";
/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBullseye,
  faHandHoldingDollar,
  faLeaf,
  faHandshake,
  faArrowsSpin,
  faServer,
  faCalendarTimes,
  faCalendarXmark
} from "@fortawesome/free-solid-svg-icons"; */
import { useState } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin-top: 2rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.primary1};
  }
`;

const ButtonLink = styled.a<{ activeColor: boolean }>`
  color: ${({ theme, activeColor }) => activeColor ? theme.primary1 : theme.text1};
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.primary1};
  }
`;

export default function MenuMobile() {
  const [isDark] = useDarkModeManager();

  let styles = {
    bmBurgerButton: {
      position: "fixed",
      width: "30px",
      height: "20px",
      top: "2.4rem",
      right: "2.5rem",
    },
    bmBurgerBars: {
      background: isDark ? "#FFF" : "#FFF",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
      marginTop: "4px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      top: 0,
      left: 0,
    },
    bmMenu: {
      background: "radial-gradient(circle, rgba(26,3,81,1) 0%, rgba(7,7,28,1) 100%)",
      fontSize: "1.15em",
      zIndex: 1,
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: isDark ? "#FFFFFF" : "#FFFFFF",
      padding: "0.8em",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
      top: 0,
    },
  };

  const [open, setOpen] = useState(false);
  const handleSetOpen = () => {
    setOpen(true);
  };
  const handleSetClose = () => {
    setOpen(false);
  };

  const [active, setActive] = useState(0);
  const handleSetActive = (n: number) => {
    setActive(n);
  };


  return (
    <StackMenu
      styles={styles}
      isOpen={open}
      onOpen={handleSetOpen}
      onClose={handleSetClose}
    >
      {/* <img src={isDark ? LogoDark : Logo} alt="gbc-logo" width={150} /> */}
      <Container>
        <AutoColumn gap="1rem">
          <Row>
            <ButtonLink id={`home-nav-link`} href="/" onClick={() => {
              handleSetClose();
              handleSetActive(0);
            }} activeColor={active === 0}>
              <Row>
                {/* <FontAwesomeIcon icon={faHome} width="24px" /> */}
                <Trans>Home</Trans>
              </Row>
            </ButtonLink>
          </Row>
          <Row>
            <ButtonLink
              id={`objectives-nav-link`}
              href="/#objectives"
              onClick={() => {
                handleSetClose();
                handleSetActive(1);
              }} activeColor={active === 1}>
              <Row>
                {/* <FontAwesomeIcon icon={faBullseye} width="24px" /> */}
                <Trans>Objectives</Trans>
              </Row>
            </ButtonLink>
          </Row>
          <Row>
            <ButtonLink
              id={`gbt-nav-link`}
              href="/#gbt"
              onClick={() => {
                handleSetClose();
                handleSetActive(2);
              }} activeColor={active === 2}>
              <Row>
                {/* <FontAwesomeIcon icon={faHandHoldingDollar} width="24px" /> */}
                GBT Token
              </Row>
            </ButtonLink>
          </Row>
          <Row>
            <ButtonLink
              id={`compounding-nav-link`}
              href="/#compounding"
              onClick={() => {
                handleSetClose();
                handleSetActive(3);
              }} activeColor={active === 3}>
              <Row>
                {/* <FontAwesomeIcon icon={faArrowsSpin} width="24px" /> */}
                <Trans>Compounding</Trans>
              </Row>
            </ButtonLink>
          </Row>
          {/* <Row>
            <ButtonLink
              id={`nft-nav-link`}
              href="/#nft"
              onClick={handleSetClose}
            >
              <Row>
                <FontAwesomeIcon icon={faFileImage} width="24px" />
                NFT Polygon
              </Row>
            </ButtonLink>
          </Row> */}
          <Row>
            <ButtonLink
              id={`values-nav-link`}
              href="/#values"
              onClick={() => {
                handleSetClose();
                handleSetActive(4);
              }} activeColor={active === 4}>
              <Row>
                {/* <FontAwesomeIcon icon={faLeaf} width="24px" /> */}
                <Trans>Values</Trans>
              </Row>
            </ButtonLink>
          </Row>
          {/* <Row>
            <ButtonLink
              id={`team-nav-link`}
              href="/#team"
              onClick={handleSetClose}
            >
              <Row>
                <FontAwesomeIcon icon={faPeopleGroup} width="24px" />
                <Trans>Team</Trans>
              </Row>
            </ButtonLink>
          </Row> */}
          <Row>
            <ButtonLink
              id={`partnership-nav-link`}
              href="/#partnership"
              onClick={() => {
                handleSetClose();
                handleSetActive(5);
              }} activeColor={active === 5}>
              <Row>
                {/* <FontAwesomeIcon icon={faHandshake} width="24px" /> */}
                <Trans>Partnership</Trans>
              </Row>
            </ButtonLink>
          </Row>
          <Row>
            <ButtonLink
              id={`host-nav-link`}
              href="/#host"
              onClick={() => {
                handleSetClose();
                handleSetActive(6);
              }} activeColor={active === 6}>
              <Row>
                {/* <FontAwesomeIcon icon={faServer} width="24px" /> */}
                <Trans>Host</Trans>
              </Row>
            </ButtonLink>
          </Row>
          <Row>
            <ButtonLink
              id={`host-nav-link`}
              href="/#events"
              onClick={() => {
                handleSetClose();
                handleSetActive(7);
              }} activeColor={active === 7}>
              <Row>
                {/* <FontAwesomeIcon icon={faCalendarXmark} width="24px" /> */}
                <Trans>Events</Trans>
              </Row>
            </ButtonLink>
          </Row>
          <Row>
            <ButtonLink
              id={`data-nav-link`}
              href="https://data-bsc.com/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                handleSetClose();
              }} activeColor={false}>
              <Row>
                Data Token
              </Row>
            </ButtonLink>
          </Row>
        </AutoColumn>
      </Container >
    </StackMenu >
  );
}
