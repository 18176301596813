import { Trans } from "@lingui/macro";
import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import { isMobile } from "utils/userAgent";
import { RowRight } from "components/Row";

import Coins from "assets/images/home/coins.png"
import Deposit from "assets/images/home/deposit.png"
import Graphic from "assets/images/home/graphic.png"
import Percentage from "assets/images/home/percentage.png"
import Platform from "assets/images/home/platform.png"
import Wallet from "assets/images/home/wallet.png"
import { ButtonExternalLinkSecondary } from "components/Link";
import { BlueCard } from "components/Card";

const Container = styled(AutoColumn)`
  margin-top: 10rem;
  gap: 2rem;
`;

const ResponsiveSections = styled.div<{ right?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  ${({ theme, right }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    justify-content: ${right ? "flex-end" : "flex-start"};
    align-items: ${right ? "flex-end" : "flex-start"};
  `};

  ${({ theme, right }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    justify-content: ${right ? "flex-end" : "flex-start"};
    align-items: ${right ? "flex-end" : "flex-start"};
  `};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 600px;
  height: 216px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    height: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 100%;
  `};
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 950px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    height: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 100%;
  `};
`;


/* const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 3rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`;

const Section = styled.div`
  background: ${({ theme }) => theme.primary1};
  padding: 16px;
  border-radius: 8px;
  margin-top: 2rem;
`;

const SectionLeft = styled.div`
  border-left: 5px solid ${({ theme }) => theme.primary1};
  padding: 8px;
`;

const SectionRight = styled.div`
  border-right: 5px solid ${({ theme }) => theme.primary1};
  padding: 8px;
`;

const ButtonLink = styled.a`
  width: fit-content;
  color: ${({ theme }) => theme.primary3};
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.success};
  }
`; */

export function GbtToken() {
  return (
    <Container id={"gbt"}>
      <ResponsiveSections>
        <Header>
          <ThemedText.Title
            color="primary1"
            textAlign={isMobile ? "center" : "left"}
          >
            GBT Token
          </ThemedText.Title>
          <ThemedText.Body>
            <span style={{ color: "#39AF45" }}>Green Block Capital </span>
            <Trans>
              offers several benefits to our stakeholders.
              Firstly, by being a part of the Bitcoin network, we are helping to
              secure and maintain the decentralized nature of the network, which
              is essential for its continued growth and stability. Additionally,
              by operating large-scale mining operations, we are able to offer a
              steady and reliable stream of mining rewards to our token holders
            </Trans>
            .
          </ThemedText.Body>
        </Header>
        <img src={Graphic} alt="graphic" width={isMobile ? "100%" : ""} />
      </ResponsiveSections>

      <ResponsiveSections>
        <img src={Percentage} alt="percentage" width={isMobile ? "106px" : ""} />
        <Column>
          <ThemedText.Body>
            <Trans>
              Our GBT token provides a unique benefit to holders, as they
              are able to earn rewards from our mining power just by holding
              and staking the token. This not only provides a passive
              income, but also helps to further secure the network by adding
              to the total mining power
            </Trans>
            .
          </ThemedText.Body>
        </Column>
      </ResponsiveSections>

      <ResponsiveSections right>
        {isMobile && <img src={Wallet} alt="wallet" width={122} />}
        <Column>
          <ThemedText.Body textAlign="right">
            <Trans>
              Receive your rewards directly to your wallet mining 1 time per
              week on Bitcoin BSC network
            </Trans>
            .
          </ThemedText.Body>
        </Column>
        {!isMobile && <img src={Wallet} alt="wallet" />}
      </ResponsiveSections>

      <ResponsiveSections>
        <img src={Platform} alt="wallet" width={isMobile ? "110px" : ""} />
        <Column>
          <ThemedText.Body>
            <Trans>
              Green Block Capital are committed to providing transparency
              and accountability to our stakeholders, and regularly publish
              updates on our mining operations and financial performance. By
              investing in Green Block Capital, you are not only supporting
              the growth of the Bitcoin network, but also have the
              opportunity to earn passive income through our GBT token
            </Trans>
            .
          </ThemedText.Body>
        </Column>
      </ResponsiveSections>

      <ResponsiveSections right>
        {isMobile && <img src={Deposit} alt="deposit" width={110} />}
        <Column>
          <ThemedText.Body textAlign={isMobile ? "right" : "left"}>
            <Trans>
              Implementing a sales tax of 10% of the token that we will use
              to buy more mining equipment and keep the equipment updated,
              always with the more efficient machines
            </Trans>
            .
          </ThemedText.Body>
          <RowRight>
            <ButtonExternalLinkSecondary
              target="_blank"
              rel="noopener noreferrer"
              width={isMobile ? "100%" : "fit-content"}
              href="https://bscscan.com/address/0x36B467433A40a445c3b65Aa058D5dFf09886F15C"
            >
              Wallet Sell Tax
            </ButtonExternalLinkSecondary>
          </RowRight>
        </Column>
        {!isMobile && <img src={Deposit} alt="deposit" />}
      </ResponsiveSections>

      <ResponsiveSections>
        <img src={Coins} alt="coins" width={isMobile ? "106px" : ""} />
        <Column>
          <ThemedText.Body>
            <Trans>
              The profitability of our business model will be totally
              sustainable, by being able to withdraw your GBT from the stake
              and sell the token once you have obtained the expected return
            </Trans>
            .
          </ThemedText.Body>
        </Column>
      </ResponsiveSections>

      <BlueCard padding="40px" mt="2rem">
        <ThemedText.Body textAlign="center">
          <Trans>
            Clean energy and efficient to low cost, high teams performance.
            Weekly they are changed to BTC and send to staked GBT holders
          </Trans>
          .
        </ThemedText.Body>
      </BlueCard>
    </Container>
  );
}
