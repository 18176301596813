import { Trans } from "@lingui/macro";
import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import DatacenterIMG from "assets/images/home/datacenter.png";
import Datacenter2IMG from "assets/images/home/datacenter2.png";
import { isMobile } from "utils/userAgent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faSquareCheck } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  margin-top: 5rem;
  margin-bottom: 5rem;
`;

const ResponsiveBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 3rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
`;

const SectionLeft = styled.div`
  border-left: 5px solid ${({ theme }) => theme.primary1};
  padding: 8px;
`;

const Mail = styled.a`
  color: #fff;
  font-size: 16px;
  text-decoration: none;
`;

export function Datacenter() {
  return (
    <Container id={"datacenter"}>

      <ResponsiveBody>
        <AutoColumn gap="2rem">
          <ThemedText.ExtraLargeHeader textAlign="center" mb="2rem">
            <Trans>Mine in our Datacenter</Trans>
          </ThemedText.ExtraLargeHeader>
          {isMobile && <img src={DatacenterIMG} alt="trading-img" width="100%" />}
          <StyledRow>
            <FontAwesomeIcon icon={faSquareCheck} style={{ fontSize: '35px' }} color="#39AF45" />
            <ThemedText.Body>
              <Trans>
                The miners will be operated in your selected crypto mining hosting facility with full transparency, including 24/7 monitoring and maintenance
              </Trans>
              .
            </ThemedText.Body>
          </StyledRow>
          <StyledRow>
            <FontAwesomeIcon icon={faSquareCheck} style={{ fontSize: '35px' }} color="#39AF45" />
            <ThemedText.Body>
              <Trans>
                You have live access to a Watcher-Link to track your mining performance anytime through web.
                We have repair room inside the site
              </Trans>
              .
            </ThemedText.Body>
          </StyledRow>


        </AutoColumn>
        {!isMobile && <img src={DatacenterIMG} alt="trading-img" width={610} />}
      </ResponsiveBody>

      <ResponsiveBody>
        <img src={Datacenter2IMG} alt="taxes-icon" width={isMobile ? "100%" : 536} />
        <AutoColumn gap="1rem">
          <ThemedText.ExtraLargeHeader textAlign="center">
            <Trans>Terms and conditions</Trans>
          </ThemedText.ExtraLargeHeader>
          <SectionLeft>
            <ThemedText.Body textAlign="left">
              <Trans>
                <b>Capacity</b>: 1 MW (expandable if needed)
              </Trans>
              .
            </ThemedText.Body>
            <ThemedText.Body textAlign="left">
              <Trans>
                <b>Energy Source</b>: Flare natural gas
              </Trans>
              .
            </ThemedText.Body>
            <ThemedText.Body textAlign="left">
              <Trans>
                <b>Electricity price</b>: 0.24-0.28AED/KWh (~$0.06-0.075 KWh)
              </Trans>
              .
            </ThemedText.Body>
          </SectionLeft>
          <SectionLeft>
            <ThemedText.Body textAlign="left">
              <Trans>
                <b>Installation fee</b>: $75/ miner in Air cooling
              </Trans>
              .
            </ThemedText.Body>
            <ThemedText.Body textAlign="left">
              <Trans>
                <b>Co-managed mining</b>: +$0.002 kWh
              </Trans>
              .
            </ThemedText.Body>
            <ThemedText.Body textAlign="left">
              <Trans>
                <b>Deposit</b>: no deposit, 3 months cycle consumption in advance
              </Trans>
              .
            </ThemedText.Body>
            <ThemedText.Body textAlign="left">
              <Trans>
                <b>Contract term</b>: 12 months initial term
              </Trans>
              .
            </ThemedText.Body>
            <ThemedText.Body textAlign="left">
              <Trans>
                <b>Cooling type</b>: Air cooling
              </Trans>
              .
            </ThemedText.Body>
            <ThemedText.Body textAlign="left">
              <Trans>
                <b>Miners type</b>: ASICs only
              </Trans>
              .
            </ThemedText.Body>
            <ThemedText.Body textAlign="left">
              <Trans>
                <b>Security</b>: Private area, alarm and video surveillance 24/7
              </Trans>
              .
            </ThemedText.Body>
            <ThemedText.Body textAlign="left">
              <Trans>
                <b>Insurance</b>: Yes, optional at 5% equipment value
              </Trans>
              .
            </ThemedText.Body>
            <ThemedText.Body textAlign="left">
              <Trans>
                Possibility to import miners from any country, subject to import taxes (10%)
              </Trans>
              .
            </ThemedText.Body>
          </SectionLeft>
          <StyledRow style={{ justifyContent: isMobile ? 'center' : 'left', alignItems: 'center', marginTop: '2rem' }}>
            <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '20px' }} />
            <Mail href="mailto:hosting@greenblockcapital.io">
              hosting@greenblockcapital.io
            </Mail>
          </StyledRow>
        </AutoColumn>
      </ResponsiveBody>
    </Container>
  );
}
