import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { LanguageProvider } from "./i18n";
import App from "./pages/App";
import store from "./state";
import ThemeProvider, { ThemedGlobalStyle } from "./theme";
import UserUpdater from "./state/user/updater";
/* import BackgroundTheme from "theme/BackgroundTheme";
import FontTheme from "theme/FontTheme"; */

function Updaters() {
  return (
    <>
      {/* <BackgroundTheme />
      <FontTheme /> */}
      <UserUpdater />
    </>
  );
}

const container = document.getElementById("root") as HTMLElement;

createRoot(container).render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <LanguageProvider>
          <Updaters />
          <ThemeProvider>
            <ThemedGlobalStyle />
            <App />
          </ThemeProvider>
        </LanguageProvider>
      </BrowserRouter>
    </Provider>
  </StrictMode>
);
