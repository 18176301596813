import { Trans } from "@lingui/macro";
/* import Slide, { Roll } from "react-reveal"; */
import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import Rewards from "assets/images/home/rewards.svg";
import RewardIcon from "assets/images/home/reward-icon.png";

import { isMobile } from "utils/userAgent";
import Row from "components/Row";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { ButtonExternalLinkPrimary } from "components/Link";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    gap: 2rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 2rem;
  `};
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 5rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 1rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 1rem;
  `};
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  `};
`;

export function Datatoken() {
  return (
    <Container id={"datatoken"}>
      <StyledColumn>
        <ThemedText.TitlePage
          color="text1"
          fontWeight={200}
        >
          <Trans>Next Mining</Trans>
          <ThemedText.TitlePage
            color="primary1"
          >
            <Trans>Rewards</Trans>
          </ThemedText.TitlePage>
        </ThemedText.TitlePage>
        {isMobile && <img src={Rewards} alt="trading-img" width={104} height={125} />}
        {!isMobile &&
          <ButtonExternalLinkPrimary href="https://app.greenblockcapital.io" target="_blank" rel="noopener noreferrer" width="306px">
            <Trans>Launch App</Trans>
          </ButtonExternalLinkPrimary>
        }
      </StyledColumn>
      <StyledRow>
        <img src={RewardIcon} alt="reward-icon" width={isMobile ? 55 : 77} height={isMobile ? 91 : 128} />
        <AutoColumn>
          <Row style={{ gap: '0.5rem', height: '2rem' }}>
            <FontAwesomeIcon icon={faArrowRight} color="#39AF45" />
            <ThemedText.Body>15th Feb - </ThemedText.Body>
            <b>$25,000</b>
          </Row>

          <Row style={{ gap: '0.5rem', height: '2rem' }}>
            <FontAwesomeIcon icon={faArrowRight} color="#39AF45" />
            <ThemedText.Body>15th March - </ThemedText.Body>
            <b>$40,000</b>
          </Row>

          <Row style={{ gap: '0.5rem', height: '2rem' }}>
            <FontAwesomeIcon icon={faArrowRight} color="#39AF45" />
            <ThemedText.Body>15th April - </ThemedText.Body>
            <b>???</b>
          </Row>

        </AutoColumn>
      </StyledRow>
      {!isMobile && <img src={Rewards} alt="trading-img" width={266} height={320} />}
      {isMobile &&
        <ButtonExternalLinkPrimary href="https://app.greenblockcapital.io" target="_blank" rel="noopener noreferrer" width="306px">
          <Trans>Launch App</Trans>
        </ButtonExternalLinkPrimary>
      }
    </Container>
  );
}
