import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import CEO from "assets/images/team/ceo.png";
import COO from "assets/images/team/coo.png";
import CLO from "assets/images/team/clo.png";
/* import CTO from "assets/images/team/cto.png";
import CMO from "assets/images/team/cmo.png"; */
import CM from "assets/images/team/cm.png";

import { isMobile } from "utils/userAgent";
import { AutoColumn } from "components/Column";
import { RowCenter } from "components/Row";

const Container = styled.div`
  margin-top: 10rem;
`;

const ResponsiveGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  gap: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr;
  `};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
`;

export function Team() {
  return (
    <Container id={"team"}>
      <ThemedText.Title
        color="primary1"
        textAlign="center"
      >
        <Trans>Our Team</Trans>
      </ThemedText.Title>
      <ResponsiveGrid>
        <AutoColumn gap="0.5rem">
          <Row >
            <img src={CEO} alt="ceo-img" width={256} />
          </Row>
          <ThemedText.MediumHeader color="primary1" textAlign={isMobile ? "center" : "left"}>
            LUIS FERNANDEZ
          </ThemedText.MediumHeader>
          <ThemedText.Body textAlign={isMobile ? "center" : "left"}>CEO</ThemedText.Body>
          <ThemedText.SubHeader textAlign={isMobile ? "center" : "left"} >
            <Trans>
              With over a decade of experience in the finance and technology
              sectors, Luis brings a wealth of knowledge and expertise to the
              team. As CEO, Luis is responsible for the overall vision and
              strategy of Green Block Capital
            </Trans>
            .
          </ThemedText.SubHeader>
        </AutoColumn>
        <AutoColumn gap="0.5rem">
          <Row>
            <img src={COO} alt="coo-img" width={256} />
          </Row>
          <ThemedText.MediumHeader color="primary1" textAlign={isMobile ? "center" : "left"}>
            ROBERTO RODRIGUEZ
          </ThemedText.MediumHeader>
          <ThemedText.Body textAlign={isMobile ? "center" : "left"}>COO</ThemedText.Body>
          <ThemedText.SubHeader textAlign={isMobile ? "center" : "left"}>
            <Trans>
              Roberto brings extensive operational experience to the team,
              having managed large-scale projects in the energy and mining
              industries. As COO, Roberto is responsible for ensuring the
              smooth and efficient running of our mining operations
            </Trans>
            .
          </ThemedText.SubHeader>
        </AutoColumn>
        <AutoColumn gap="0.5rem">
          <Row>
            <img src={CLO} alt="clo-img" width={256} />
          </Row>
          <ThemedText.MediumHeader color="primary1" textAlign={isMobile ? "center" : "left"}>
            NACHO BLAT
          </ThemedText.MediumHeader>
          <ThemedText.Body textAlign={isMobile ? "center" : "left"}>CLO</ThemedText.Body>
          <ThemedText.SubHeader textAlign={isMobile ? "center" : "left"}>
            <Trans>
              Ignacio is a highly skilled and experienced lawyer, with a focus
              on the cryptocurrency and blockchain industries. As our in-house
              legal expert, Ignacio provides valuable guidance and support on
              a range of legal and regulatory issues
            </Trans>
            .
          </ThemedText.SubHeader>
        </AutoColumn>
        <AutoColumn gap="0.5rem">
          <RowCenter mb="3rem">
            <img src={CM} alt="cm-img" width={256} />
          </RowCenter>
          <ThemedText.MediumHeader color="primary1" mt="1rem" textAlign={isMobile ? "center" : "left"}>
            SAID KOABARI
          </ThemedText.MediumHeader>
          <ThemedText.Body textAlign={isMobile ? "center" : "left"}>MANAGER</ThemedText.Body>
          <ThemedText.SubHeader textAlign={isMobile ? "center" : "left"} height={126}>
            <Trans>Said is responsible of the operations in UAE searching for good investment opportunities and partnerships</Trans>
            .
          </ThemedText.SubHeader>
        </AutoColumn>
        {/* <AutoColumn gap="0.5rem">
            <RowCenter>
              <img src={CTO} alt="cto-img" width={256} />
            </RowCenter>
            <ThemedText.MediumHeader textAlign="center">
              VICENTE CHACON
            </ThemedText.MediumHeader>
            <ThemedText.Body textAlign={isMobile ? "center" : "left"}>CTO</ThemedText.Body>
            <ThemedText.SubHeader textAlign={isMobile ? "center" : "left"}>
              <Trans>
                Vicente is a seasoned technologist with a deep understanding of
                the cryptocurrency industry. As CTO, Vicente is responsible for
                the technical direction of the company and the development of
                new products and services
              </Trans>
              .
            </ThemedText.SubHeader>
          </AutoColumn> */}

        {/* <AutoColumn gap="0.5rem">
            <RowCenter>
              <img src={CMO} alt="cmo-img" width={256} />
            </RowCenter>
            <ThemedText.MediumHeader textAlign="center">
              CARLOS DUAT
            </ThemedText.MediumHeader>
            <ThemedText.Body textAlign={isMobile ? "center" : "left"}>CMO</ThemedText.Body>
            <ThemedText.SubHeader textAlign={isMobile ? "center" : "left"}>
              <Trans>
                Carlos is the head of marketing at Green Block Capital, with
                many years of experience in advertising, Carlos brings great
                visibility to the project, market analysis and great attention
                to holders
              </Trans>
              .
            </ThemedText.SubHeader>
          </AutoColumn> */}
      </ResponsiveGrid>
    </Container>
  );
}
