import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import Agility from "assets/images/home/agility.png";
import Integrity from "assets/images/home/integrity.png";
import Innovation from "assets/images/home/innovation.png";
import { isMobile } from "utils/userAgent";
import { BlueCard } from "components/Card";
import { AutoColumn } from "components/Column";

const Container = styled.div`
  margin-top: 10rem;
  gap: 4rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`;

const ResponsiveBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    gap: 1rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 1rem;
  `};
`;

const Card = styled(BlueCard)`
  width: 377px;
  height: 600px;
  padding: 24px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    height: 100%;
    padding: 16px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 100%;
    padding: 16px;
  `};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    align-items:  flex-end;
  `};
`;

export function Values() {
  return (
    <Container id={"values"}>
      <ThemedText.Title
        color="primary1"
        textAlign="center"
      >
        <Trans>Values</Trans>
      </ThemedText.Title>

      <ResponsiveBox>
        <Card>
          <AutoColumn>
            <Column>
              <img src={Agility} alt="agility-ico" />
            </Column>
            <ThemedText.LargeHeader mt="4rem" textAlign={isMobile ? "left" : "center"}>
              <Trans>Agility</Trans>
            </ThemedText.LargeHeader>
            <ThemedText.MediumHeader mt="2rem" width={isMobile ? "100%" : "296px"} height={300}>
              <Trans>
                Our extensive global team and network form a robust foundation, enabling us to swiftly adapt and respond to shifts in our operating environment. This flexibility empowers us to proactively seek and implement effective solutions to address evolving challenges
              </Trans>
              .
            </ThemedText.MediumHeader>
          </AutoColumn>
        </Card>

        <Card>
          <AutoColumn>
            <Column>
              <img src={Integrity} alt="integrity-ico" />
            </Column>
            <ThemedText.LargeHeader mt="4rem" textAlign={isMobile ? "left" : "center"}>
              <Trans>Integrity</Trans>
            </ThemedText.LargeHeader>
            <ThemedText.MediumHeader mt="2rem" width={isMobile ? "100%" : "296px"} height={300}>
              <Trans>
                We put technology at the forefront of everything we do, from working with investors to stakeholders and community members. We are transparent and take a holistic, values-based approach to decision-making
              </Trans>
              .
            </ThemedText.MediumHeader>
          </AutoColumn>
        </Card>

        <Card>
          <AutoColumn>
            <Column>
              <img src={Innovation} alt="innovation-ico" />
            </Column>
            <ThemedText.LargeHeader mt="4rem" textAlign={isMobile ? "left" : "center"}>
              <Trans>Innovation</Trans>
            </ThemedText.LargeHeader>
            <ThemedText.MediumHeader mt="2rem" width={isMobile ? "100%" : "296px"} height={300}>
              <Trans>
                We prioritize innovation in engineering and sustainable business models. Through software development and tools, we aim to enhance project access, management, and asset sharing for increased value to stakeholders
              </Trans>
              .
            </ThemedText.MediumHeader>
          </AutoColumn>
        </Card>
      </ResponsiveBox>
    </Container>
  );
}
