import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { useState } from "react";
import Row from "components/Row";


const HeaderLinks = styled(Row)`
  justify-self: center;
  width: fit-content;
  padding: 2px;
  border-radius: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    justify-self: start;  
    `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: center;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    z-index: 99;
    position: fixed;
    bottom: 0; right: 50%;
    transform: translate(50%,-50%);
    margin: 0 auto;
    background-color: ${({ theme }) => theme.bg0};
    border: 1px solid ${({ theme }) => theme.bg3};
    box-shadow: 0px 6px 10px rgb(0 0 0 / 2%);
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 70%;
  `};
`

const ButtonLink = styled.a<{ activeColor: boolean }>`
  color: ${({ theme, activeColor }) => activeColor ? theme.primary1 : theme.text1};
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.primary1};
  }
`;
const StyledRow = styled(Row)`
  gap: 2rem;
`;

export default function Menu() {
  const [active, setActive] = useState(0);
  const handleSetActive = (n: number) => {
    setActive(n);
  };


  return (
    <HeaderLinks>
      <StyledRow>
        <ButtonLink id={`home-nav-link`} href="/" onClick={() => {
          handleSetActive(0);
        }} activeColor={active === 0}>
          <Trans>Home</Trans>
        </ButtonLink>


        <ButtonLink
          id={`objectives-nav-link`}
          href="/#objectives"
          onClick={() => {

            handleSetActive(1);
          }} activeColor={active === 1}>
          <Trans>Objectives</Trans>
        </ButtonLink>


        <ButtonLink
          id={`gbt-nav-link`}
          href="/#gbt"
          onClick={() => {

            handleSetActive(2);
          }} activeColor={active === 2}>
          GBT Token
        </ButtonLink>


        <ButtonLink
          id={`compounding-nav-link`}
          href="/#compounding"
          onClick={() => {

            handleSetActive(3);
          }} activeColor={active === 3}>
          <Trans>Compounding</Trans>
        </ButtonLink>


        <ButtonLink
          id={`values-nav-link`}
          href="/#values"
          onClick={() => {

            handleSetActive(4);
          }} activeColor={active === 4}>
          <Trans>Values</Trans>
        </ButtonLink>


        <ButtonLink
          id={`partnership-nav-link`}
          href="/#partnership"
          onClick={() => {

            handleSetActive(5);
          }} activeColor={active === 5}>
          <Trans>Partnership</Trans>
        </ButtonLink>


        <ButtonLink
          id={`host-nav-link`}
          href="/#host"
          onClick={() => {

            handleSetActive(6);
          }} activeColor={active === 6}>
          <Trans>Host</Trans>
        </ButtonLink>


        <ButtonLink
          id={`host-nav-link`}
          href="/#events"
          onClick={() => {

            handleSetActive(7);
          }} activeColor={active === 7}>
          <Trans>Events</Trans>
        </ButtonLink>

        <ButtonLink
          id={`data-nav-link`}
          href="https://data-bsc.com/"
          target="_blank"
          rel="noopener noreferrer"
          activeColor={false}>
          Data Token
        </ButtonLink>

      </StyledRow>
    </HeaderLinks>
  );
}
