import { Trans } from "@lingui/macro";
import { ButtonExternalLinkPrimary, ButtonExternalLinkSecondary } from "components/Link";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { isMobile } from "utils/userAgent";
import Logo from "assets/images/logoh-white.png";
import { AutoColumn } from "components/Column";
import { GreyCard } from "components/Card";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    gap: 2rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 2rem;
  `};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

const BodyText = styled(AutoColumn)`
    left: 50%;
    position: relative;
    top: 50%;
    transform: translate(-50%, -50%);
    gap: 0.5rem;
    padding: 16px;
`;


export function GreenBlockData() {
    return (
        <Container id={"gbdata"}>
            <GreyCard width={isMobile ? "100%" : "419px"} height="287px">
                <BodyText>
                    <ThemedText.Body>
                        <span style={{ color: '#39AF45' }}>Green Block Capital </span>
                        <Trans>
                            is a Bitcoin mining company, supporting the Bitcoin blockchain through rapidly expanding large-scale mining in Georgia and Abu Dhabi. We believe in the future of Bitcoin mining and we focus our efforts on increasing the infrastructure and the mining rate to be the vehicle that leads to success in your operations
                        </Trans>
                        .
                    </ThemedText.Body>
                </BodyText>
            </GreyCard>
            {!isMobile && <Column>
                <img src={Logo} alt="gbc-logo" width={217} height={209} />
                <Row>
                    <ButtonExternalLinkPrimary
                        href="https://app.greenblockcapital.io/#/stake"
                        target="_blank"
                        rel="noopener noreferrer"
                        width="fit-content"
                    >
                        <ThemedText.Body>
                            Stake GBT
                        </ThemedText.Body>
                    </ButtonExternalLinkPrimary>
                    <ButtonExternalLinkSecondary
                        href="https://pancakeswap.finance/swap?outputCurrency=0xb8d5CC1462bf5a28366d58FfD4d40d5d26030F3F"
                        target="_blank"
                        rel="noopener noreferrer"
                        width="fit-content"
                    >
                        <ThemedText.Body>
                            <Trans>Buy</Trans> GBC
                        </ThemedText.Body>
                    </ButtonExternalLinkSecondary>
                </Row>
            </Column>}
            <GreyCard width={isMobile ? "100%" : "419px"} height="287px">
                <BodyText style={{ marginLeft: isMobile ? '1rem' : '2rem' }}>
                    <ThemedText.LargeHeader color="primary1" mb="2rem">
                        GBC Stealth Launch
                    </ThemedText.LargeHeader>
                    <ThemedText.Body>
                        <Trans>Initial liquidity</Trans>: <span style={{ fontWeight: 'bold' }}>$30,000</span>
                    </ThemedText.Body>
                    <ThemedText.Body>
                        <Trans>Launch price</Trans>: <span style={{ fontWeight: 'bold' }}>$0.002</span>
                    </ThemedText.Body>
                    <ThemedText.Body>
                        <Trans>GBC for LP</Trans>: <span style={{ fontWeight: 'bold' }}>5,000,000 GBC</span>
                    </ThemedText.Body>
                    <ThemedText.Body>
                        <Trans>Sell tax</Trans>: <span style={{ fontWeight: 'bold' }}>10%</span>
                    </ThemedText.Body>
                </BodyText>
            </GreyCard>
            {isMobile && <Column>
                <img src={Logo} alt="gbc-logo" width={217} height={209} />
                <Row>
                    <ButtonExternalLinkPrimary
                        href="https://app.greenblockcapital.io"
                        target="_blank"
                        rel="noopener noreferrer"
                        width="fit-content"
                    >
                        <ThemedText.Body>
                            Stake GBT
                        </ThemedText.Body>
                    </ButtonExternalLinkPrimary>
                    <ButtonExternalLinkSecondary
                        href="https://pancakeswap.finance/swap?outputCurrency=0xb8d5CC1462bf5a28366d58FfD4d40d5d26030F3F"
                        target="_blank"
                        rel="noopener noreferrer"
                        width="fit-content"
                    >
                        <ThemedText.Body>
                            <Trans>Buy</Trans> GBT
                        </ThemedText.Body>
                    </ButtonExternalLinkSecondary>
                </Row>
            </Column>}
        </Container>
    );
}
