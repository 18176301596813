import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import CarouselItem from './CarouselItem';
import { RowAround } from 'components/Row';

const CarouselContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Button = styled.button`
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
`;

const PrevButton = styled(Button)`
    color: #39AF45;
    background: white;
    border-radius: 50%;
    cursor: pointer;
    fontSize: 20px;
    height: 40px;
    line-height: 1;
    text-align: center;
    width: 40px;
`;

const NextButton = styled(Button)`
    color: #39AF45;
    background: white;
    border-radius: 50%;
    cursor: pointer;
    fontSize: 20px;
    height: 40px;
    line-height: 1;
    text-align: center;
    width: 40px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-top: 1rem;
`;

interface CarouselProps {
    images: string[];
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const touchStartX = useRef<number | null>(null);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = (e: React.TouchEvent) => {
        if (touchStartX.current !== null) {
            const touchEndX = e.changedTouches[0].clientX;
            const deltaX = touchEndX - touchStartX.current;

            if (deltaX > 50) {
                // Swipe to the right, go to previous image
                handlePrev();
            } else if (deltaX < -50) {
                // Swipe to the left, go to next image
                handleNext();
            }

            touchStartX.current = null;
        }
    };

    return (
        <CarouselContainer onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
            <CarouselItem isVisible imageUrl={images[currentIndex]} />
            <Row>
                <PrevButton onClick={handlePrev}><div style={{ marginBottom: '5px' }}>←</div></PrevButton>
                <NextButton onClick={handleNext}><div style={{ marginBottom: '5px' }}>→</div></NextButton>
            </Row>
        </CarouselContainer>
    );
};

export default Carousel;