import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import { isMobile } from "utils/userAgent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { GreyCard } from "components/Card";

import Compounding from "assets/images/home/compounding.png"

const Container = styled.div`
  margin-top: 10rem;
  gap: 4rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`;

const ResponsiveBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    gap: 2rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 2rem;
  `};
`;

const Image = styled.img`
  width: 436px;
  height: 420.4px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    height: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 100%;
  `};
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 13px;
    margin-bottom: 0.5rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 13px;
    margin-bottom: 0.5rem;
  `};
`;


export function MiningPower() {
  return (
    <Container id={"compounding"}>
      <ThemedText.Title
        color="primary1"
        textAlign="center"
      >
        <Trans>Compounding Mining Power</Trans>
      </ThemedText.Title>
      <ResponsiveBox>
        <Image src={Compounding} alt="graphic-compounding" />
        <GreyCard width={isMobile ? "100%" : "535px"} padding={isMobile ? "40px" : "40px"} >
          <ThemedText.LargeHeader color="primary1" mb="2rem">
            <Trans>States</Trans>
          </ThemedText.LargeHeader>
          <StyledRow>
            <FontAwesomeIcon
              icon={faCircle}
              color={"#81E08B"}
            />
            <Trans>More miners = More hashrate</Trans>
          </StyledRow>
          <StyledRow>
            <FontAwesomeIcon
              icon={faCircle}
              color={"#55E664"}
            />
            <Trans>More hashrate = More rewards in Bitcoin</Trans>
          </StyledRow>
          <StyledRow>
            <FontAwesomeIcon
              icon={faCircle}
              color={"#39AF45"}
            />
            <Trans>More rewards in Bitcoin = More value</Trans>
          </StyledRow>
          <StyledRow>
            <FontAwesomeIcon
              icon={faCircle}
              color={"#2A9234"}
            />
            <Trans>More value = Price go up</Trans>
          </StyledRow>
          <StyledRow>
            <FontAwesomeIcon
              icon={faCircle}
              color={"#207728"}
            />
            <Trans>Price go up = Sells</Trans>
          </StyledRow>
          <StyledRow>
            <FontAwesomeIcon
              icon={faCircle}
              color={"#106318"}
            />
            <Trans>Sells = Funds to buy miners</Trans>
          </StyledRow>
        </GreyCard>
      </ResponsiveBox>
    </Container >
  );
}
