import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'utils/userAgent';

const ItemContainer = styled.div<{ isVisible: boolean }>`
  flex: 0 0 100%;
`;

interface CarouselItemProps {
    imageUrl: string;
    isVisible: boolean;
}

const CarouselItem: React.FC<CarouselItemProps> = ({ imageUrl, isVisible }) => {
    return <ItemContainer isVisible={isVisible}><img src={imageUrl} alt="carousel-item" width={isMobile ? '100%' : ''} /></ItemContainer>;
};

export default CarouselItem;