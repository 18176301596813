import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";

const Card = styled(Box) <{
  width?: string;
  padding?: string;
  border?: string;
  $borderRadius?: string;
}>`
  width: ${({ width }) => width ?? "100%"};
  padding: ${({ padding }) => padding ?? "1rem"};
  border-radius: ${({ $borderRadius }) => $borderRadius ?? "16px"};
  border: ${({ border }) => border};
`;
export default Card;

export const LightCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.bg2};
  background-color: ${({ theme }) => theme.bgCard1};
`;

export const LightGreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg2};
`;

export const GreyCard = styled(Card)`
  box-sizing: border-box;
  background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(174, 165, 255, 0.1) 0%, rgba(174, 165, 255, 0.1) 0%, rgba(174, 165, 255, 0.1) 0.01%, rgba(110, 191, 244, 0.0223958) 77.08%, rgba(70, 144, 213, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  backdrop-filter: blur(40px);
  border-radius: 20px;
  border-top: 1px outset ${({ theme }) => theme.primary2};
  border-left: 1px outset ${({ theme }) => theme.primary2};
  border-bottom: 1px outset ${({ theme }) => theme.primary2};
  border-right: 1px instet #fff;
`;

export const DarkGreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg2};
`;

export const DarkCard = styled(Card)`
  background-color: ${({ theme }) => theme.bgCard0};
`;

export const OutlineCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.bg3};
`;

export const YellowCard = styled(Card)`
  background-color: rgba(243, 132, 30, 0.05);
  color: ${({ theme }) => theme.yellow3};
  font-weight: 500;
`;

export const BlueCard = styled(Card)`
  background-color: ${({ theme }) => theme.primary4};
  color: ${({ theme }) => theme.blue2};
  border-radius: 20px;
  box-sizing: border-box;
  backdrop-filter: blur(40px);
  border-top: 1px outset ${({ theme }) => theme.primary2};
  border-left: 1px outset ${({ theme }) => theme.primary2};
  border-bottom: 1px outset ${({ theme }) => theme.primary2};
  border-right: 1px outset #454866;
`;

export const DarkGreenCard = styled(Card)`
  background-color: #001400;
  border: 1px solid ${({ theme }) => theme.primary1};
  border-radius: 20px;
`;

export const BlueDataCard = styled(Card)`
  background: rgba(40, 100, 151, 0.27);
  backdrop-filter: blur(25px);
  border-radius: 50px;
`;

export const DarkBlueDataCard = styled(Card)`
  background: #050B2A;
  backdrop-filter: blur(25px);
  border-radius: 50px;
`;


export const BorderCard = styled.div`
    height: 100%;
    padding: 40px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 10px;
        right: 1rem; 
        bottom: 10px; 
        left: 1rem; 
        background-image: url(${process.env.PUBLIC_URL}/images/border.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 1;
    }

    ${({ theme }) => theme.mediaWidth.upToMedium`
        &::before {
            top: 10px;
            right: 10px;
            bottom: 10px;
            left: 10px;
        }
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        &::before {
            top: 10px;
            right: 10px;
            bottom: 10px;
            left: 10px;
        }
    `};
`;

export const BorderLargeCard = styled.div`
    height: 100%;
    padding: 40px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 10px;
        right: 1rem; 
        bottom: 10px; 
        left: 1rem; 
        background-image: url(${process.env.PUBLIC_URL}/images/border-large.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 1;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
        &::before {
            top: 10px;
            right: 10px;
            bottom: 10px;
            left: 10px;
        }
    `};
`;
