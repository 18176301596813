import 'react-multi-carousel/lib/styles.css'

import styled from "styled-components/macro";

/* import Image1 from "assets/images/events/event1-1.png"; */
import Image2 from "assets/images/events/event1-2.png";
import Image3 from "assets/images/events/event1-3.png";
import Image4 from "assets/images/events/event1-4.png";
import Image5 from "assets/images/events/event1-5.png";
import Image6 from "assets/images/events/event1-6.png";
import Image7 from "assets/images/events/event1-7.png";
import Image8 from "assets/images/events/event1-8.png";
import Image9 from "assets/images/events/event1-9.png";

import { isMobile } from "utils/userAgent";

import ReactSimplyCarousel from 'react-simply-carousel';
import { useState } from 'react';

import Carousel from 'components/Carousel/Carousel'

const NftImg = styled.img`
  width: 538px;
  height: 538px;
  border-radius: 24px;
  
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 300px;
    height: 300px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 300px;
    height: 300px;
  `};
`


const Container = styled.div`
  width: 100%;
`;




export function Gallery() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const imagesMobile = [Image9, Image2, Image3, Image4, Image5, Image6, Image7, Image8]
  return (
    <Container>
      {isMobile ? <Carousel images={imagesMobile} /> : <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        centerMode
        containerProps={{
          style: {
            width: "80%",
            justifyContent: "center",
            userSelect: "none",
          }
        }}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          show: isMobile ? false : true,
          style: {
            alignSelf: 'center',
            background: 'white',
            border: 'none',
            borderRadius: '50%',
            color: '#39AF45',
            cursor: 'pointer',
            fontSize: '20px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <div style={{ marginBottom: '5px' }}>{`→`}</div>,
        }}
        backwardBtnProps={{
          show: isMobile ? false : true,
          style: {
            alignSelf: 'center',
            background: 'white',
            border: 'none',
            borderRadius: '50%',
            color: '#39AF45',
            cursor: 'pointer',
            fontSize: '20px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <div style={{ marginBottom: '5px' }}>{`←`}</div>,
        }}
        responsiveProps={[
          {
            itemsToShow: 1,
            itemsToScroll: 1,
          },
        ]}
        speed={400}
        easing="linear"
      >
        <NftImg src={Image2} />
        <NftImg src={Image3} />
        <NftImg src={Image4} />
        <NftImg src={Image5} />
        <NftImg src={Image6} />
        <NftImg src={Image7} />
        <NftImg src={Image8} />
        <NftImg src={Image9} />
      </ReactSimplyCarousel>}


    </Container>

  );
}
